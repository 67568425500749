<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      ref="orderForm"
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="80"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item label="订单编号" slot="orderNo" style="margin:0">
        <el-input clearable placeholder="请输入订单编号" v-model="orderNo">
        </el-input>
      </el-form-item>
      <el-form-item label="串号" slot="imei" style="margin:0" label-width="55px">
        <el-input clearable placeholder="请输入串号" v-model="imei"> </el-input>
      </el-form-item>
      <el-form-item label="回收商家" slot="merchantId" style="margin:0">
        <el-select
          v-model="merchantId"
          placeholder="请选择"
          filterable
          clearable
          @change="handleChange"
        >
          <el-option
            v-for="item in shanpsSelectList"
            :key="item.merchantId"
            :label="item.merchantEnterType == '01'? item.merchantName +'(外部)' : item.merchantName"
            :value="item.merchantId"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 询价商家改成多选 -->
      <el-form-item
        label="询价商家"
        slot="companyId"
        style="margin:0"
        class="query"
      >
        <el-select
          multiple
          collapse-tags
          v-model="companyIds"
          @change="changeSelect"
          @remove-tag="removeTag"
          placeholder="请选择"
          filterable
          clearable
          reserve-keyword
        >
          <el-option
            v-for="(item, index) in InquirySelectList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- ***************************** -->
      <el-form-item label="询价门店" slot="storeId" style="margin:0">
        <el-select
          v-model="storeId"
          placeholder="请先选择门店商家"
          :disabled="isHasCompany"
          filterable
          clearable
          @change="handleChangeStore"
        >
          <el-option
            v-for="item in storeList"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="下单店员" slot="staffName" style="margin:0">
        <el-input
          clearable
          :disabled="isHasCompany"
          placeholder="请先选择门店商家"
          v-model="staffName"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="订单状态" slot="state" style="margin:0">
        <el-select
          v-model="state"
          multiple
          placeholder="请选择订单状态"
          clearable
        >
          <el-option
            v-for="item in orderStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label-width="'100px'"
        label="是否竞价成功"
        slot="bidding"
        style="margin:0"
      >
        <el-select v-model="chxBiddingState" clearable>
          <el-option
            v-for="item in biddingList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label-width="'80px'"
        label="回收分类"
        slot="typeId"
        style="margin:0"
      >
        <el-select clearable @change="recoverySeparator" v-model="typeId">
          <el-option
            v-for="item in classList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label-width="'80px'"
        label="回收品牌"
        slot="brandNo"
        style="margin:0"
      >
        <el-select clearable filterable v-model="brandNo" @change="recoverySeparator">
          <el-option
            v-for="item in brandNoListData"
            :key="item.id"
            :label="item.name"
            :value="item.brandNo"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label-width="'80px'"
        label="回收商品"
        slot="phoneName"
        style="margin:0"
      >
        <el-select placeholder="请先选择分类" filterable :disabled="!typeId" clearable v-model="modelCode">
          <el-option
            v-for="item in modelListData"
            :key="item.modelNo"
            :label="item.name"
            :value="item.modelNo"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label-width="'100px'"
        label="是否线上支付"
        slot="operation1"
        style="margin:0"
      >
        <el-select v-model="isEnablePay">
          <el-option
            v-for="item in optionList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label-width="'125px'"
        label="是否可付款给用户"
        slot="operation"
        style="margin:0"
      >
        <el-select v-model="userPayeeMode">
          <el-option
            v-for="item in optionList2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label-width="'120px'"
        label="是否付款给用户"
        slot="operation"
        style="margin:0"
      >
        <el-select v-model="userIsCollect">
          <el-option
            v-for="item in optionList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        :label-width="'120px'"
        label="是否加价给用户"
        slot="isUsedAddPrice"
        style="margin:0"
      >
        <el-select v-model="isUsedAdd">
          <el-option
            v-for="item in isUsedAddPrice"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="外部订单号" slot="orderNoOneline" style="margin:0" label-width="90px">
        <el-input
          clearable
          placeholder="请输入外部订单号"
          v-model="orderNoOneline"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="运单查询" slot="expressNo" style="margin:0">
        <el-input clearable placeholder="请输入运单查询" v-model="expressNo">
        </el-input>
      </el-form-item>

      <el-form-item label="下单时间" slot="entryTime" style="margin:0">
        <el-date-picker
          v-model="entryTime"
          type="daterange"
          start-placeholder="开始日期"
          range-separator="~"
          end-placeholder="结束日期"
          clearable
          @change="dateChange"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="成交时间" slot="entryTime2" style="margin:0">
        <el-date-picker
          v-model="entryTime2"
          type="daterange"
          start-placeholder="开始日期"
          range-separator="~"
          end-placeholder="结束日期"
          clearable
          @change="dateChange2"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
      <div slot="entryTime3" class="flex">
        <el-select
          v-model="otherDateType"
          class="time-type"
          style="width:125px;border:none"
        >
          <el-option
            v-for="ele in timeTypeOption"
            :key="ele.key"
            :label="ele.label"
            :value="ele.key"
          >
          </el-option>
        </el-select>
        <el-date-picker
          v-model="entryTime3"
          type="daterange"
          start-placeholder="开始日期"
          range-separator="~"
          end-placeholder="结束日期"
          clearable
          @change="dateChange2"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </div>
      <template slot="AddSlot">
        <el-button
          type="danger"
          icon="el-icon-delete"
          @click="clearQueryCriteria"
          >清除查询条件</el-button
        >
      </template>
    </GlobalForm>
  </div>
</template>

<script>
import { formValidateMobile } from "@/utils/form-validate";
import moment from "moment";
import _api from "@/utils/request";
import { getHealthCode, getIndex, setValue } from "@/utils/common";
export default {
  name: "Form",
  props: {
    classList: Array,
    default: () => {
      return [];
    },
  },
  data() {
    let _this = this;
    return {
      isUsedAdd: "",
      staffName: "",
      companyId: "",
      companyIds: [], //修改询价商家为多选
      merchantId: "",
      storeId: "",
      entryTime: null,
      entryTime2: null,
      entryTime3: null,
      InquirySelectList: [],
      shanpsSelectList: [],
      storeList: [],
      initData: {
        healthCode: null,
      },
      expressNo: "",
      orderNoOneline: "",
      phoneName: "",
      modelCode:"",
      modelListData:[],
      brandNoListData:[],
      typeId:"",
      brandNo:"",
      orderNo: "",
      imei: "",
      startTime: "",
      endTime: "",
      ackStartTime: "",
      ackEndTime: "",
      otherStartTime: "",
      otherEndTime: "",
      isHasCompany: true,
      orderStatusList: [],
      state: [],
      isEnablePay: "",
      userIsCollect: "",
      userPayeeMode: "",
      formItemList: [
        { slotName: "orderNo" },
        { slotName: "imei" },
        // 新加 撮合渠道
        {
          labelName: "撮合渠道",
          key: "matchId",
          type: "selectFilterable",
          placeholder: "请选择",
          lableWidth: "80px",
          clear: true,
          option: [],
          clearFun: () => false,
        },
        { slotName: "merchantId" },
        { slotName: "companyId" },
        { slotName: "storeId" },
        { slotName: "staffName" },
        { slotName: "state" },
        { slotName: "typeId" },
        { slotName: "brandNo" },
        { slotName: "phoneName" },
        // {
        //   key: "typeId",
        //   type: "select",
        //   labelName: "回收分类",
        //   placeholder: "请选择分类",
        //   option: this.classList,
        // },
        {
          key: "recycleWay",
          type: "select",
          labelName: "回收方式",
          placeholder: "请选择",
          option: [
            {
              value: "01",
              label: "以旧换新",
            },
            {
              value: "02",
              label: "仅回收",
            },
          ],
        },
        {slotName:"bidding"},
        /*********************************************** 更改位置 */
        {
          key: "isBindBarCode",
          type: "select",
          labelName: "是否绑码",
          placeholder: "请选择",
          option: [
            {
              value: "01",
              label: "已绑码",
            },
            {
              value: "02",
              label: "未绑码",
            },
          ],
        },
        /*********************************************** 更改位置 */
        {
          key: "channel",
          type: "select",
          labelName: "订单类型",
          placeholder: "请选择",
          option: [
            {
              value: "Store",
              label: "门店询价订单",
            },
            {
              value: "Merchant",
              label: "回收商自建订单",
            },
          ],
        },
        // {
        //   key: "isNiceBiz",
        //   type: "select",
        //   lableWidth: "125",
        //   labelName: "是否为靓机订单",
        //   option: [
        //     {
        //       value: "",
        //       label: "全部",
        //     },
        //     {
        //       value: true,
        //       label: "是",
        //     },
        //     {
        //       value: false,
        //       label: "否",
        //     },
        //   ],
        // },

        { slotName: "entryTime" },
        { slotName: "entryTime2" },
        { slotName: "entryTime3" },
        { slotName: "operation1" },
        { slotName: "operation" },
        { slotName: "isUsedAddPrice" },
        // {
        //   key: "isNiceDevice",
        //   type: "select",
        //   lableWidth: "125",
        //   labelName: "是否标记靓机",
        //   option: [
        //     {
        //       value: "",
        //       label: "全部",
        //     },
        //     {
        //       value: true,
        //       label: "是",
        //     },
        //     {
        //       value: false,
        //       label: "否",
        //     },
        //   ],
        // },
        {
          key: "isReBuild",
          type: "select",
          lableWidth: "125",
          labelName: "是否为重评订单",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: true,
              label: "是",
            },
            {
              value: false,
              label: "否",
            },
          ],
        },
        {
          key: "isInside",
          type: "select",
          lableWidth: "125",
          labelName: "内部加价成交",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: true,
              label: "是",
            },
            {
              value: false,
              label: "否",
            },
          ],
        },
        {
          key: "niceAckPayment",
          type: "select",
          lableWidth: "110",
          labelName: "订单付款方",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: "01",
              label: "成交商家付款",
            },
            {
              value: "02",
              label: "靓机商家付款",
            },
          ],
        },
        { slotName: "orderNoOneline" },
        {
          key: "shopType",
          type: "select",
          lableWidth: "110",
          labelName: "门店类型",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: "1",
              label: "直营店",
            },
            {
              value: "2",
              label: "专卖店",
            },
          ],
        },
        {
          key: "isExpected",
          type: "select",
          lableWidth: "110",
          labelName: "是否议价",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: true,
              label: "是",
            },
            {
              value: false,
              label: "否",
            },
          ],
        },
        {
          key: "expState",
          type: "select",
          lableWidth: "110",
          labelName: "议价审批",
          option: [
            {
              value: '01',
              label: "审批通过",
            },
            {
              value: '02',
              label: "审批拒绝",
            },
          ],
        },
        { slotName: "expressNo" },
        {
          key: "isChxRebuild",
          type: "select",
          lableWidth: "140px",
          labelName: "是否同串号重评订单",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: true,
              label: "是",
            },
            {
              value: false,
              label: "否",
            },
          ],
        },
        {
          key: "isSameImeiAutoQuote",
          type: "select",
          lableWidth: "140px",
          labelName: "是否为同串号拉齐",
          option: [
            // {
            //   value: "",
            //   label: "全部",
            // },
            {
              value: '00',
              label: "是",
            },
            {
              value: '01',
              label: "否",
            },
          ],
        },
        {
          key: "receiptType",
          type: "select",
          lableWidth: "80px",
          labelName: "收货方",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: "01",
              label: "回收商",
            },
            {
              value: "02",
              label: "平台",
            },
          ],
        },
        {
          key: "isReimg",
          type: "select",
          lableWidth: "105px",
          labelName: "是否发起补拍",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: true,
              label: "是",
            },
            {
              value: false,
              label: "否",
            },
          ],
        },
        {
          key: "isAuto",
          type: "select",
          lableWidth: "105px",
          labelName: "是否自动报价",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: true,
              label: "是",
            },
            {
              value: false,
              label: "否",
            },
          ],
        },
        // {
        //   key: "makeUpType",
        //   type: "select",
        //   lableWidth: "105px",
        //   labelName: "平台加价",
        //   option: [
        //     {
        //       value: "",
        //       label: "全部",
        //     },
        //     {
        //       value: "00",
        //       label: "手动加价",
        //     },
        //     {
        //       value: "01",
        //       label: "自动加价",
        //     },
        //   ],
        // },
        {
          key: "makeUpTypePlatform",
          type: "select",
          labelName: "平台加价",
          lableWidth:'80px',
          placeholder: "请选择",
          option: [
            {
              value: "00",
              label: "手动加价",
            },
            {
              value: "01",
              label: "自动加价",
            },
            {
              value: "02",
              label: "补贴加价",
            },
          ],
        },
        {
          key: "signType",
          type: "select",
          lableWidth: "80px",
          labelName: "物流状态",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: "01",
              label: "发货异常",
            },
            {
              value: "02",
              label: "未签收",
            },
            {
              value: "03",
              label: "超时未签收",
            },
            {
              value: "04",
              label: "已签收",
            },
          ],
        },
        // 旧机情况
        {
          key: "healthCode",
          type: "select",
          lableWidth: "80px",
          labelName: "旧机情况",
          option: [],
        },
        {
          key: "openMachineCondition",
          type: "select",
          labelName: "开机情况",
          lableWidth:'80px',
          placeholder: "请选择",
          option: [
            {
              value: "canStart",
              label: "正常开机",
            },
            {
              value: "unStart",
              label: "无法开机",
            }, {
              value: "elderly",
              label: "功能机/老年机",
            }, {
              value: "brand-new",
              label: "全新机",
            },
          ],
        },
        {
          key: "isRenewSubsidy",
          type: "select",
          labelName: "是否使用换新补贴",
          lableWidth:'130px',
          placeholder: "请选择",
          option: [
            {
              value: "00",
              label: "是",
            },
            {
              value: "01",
              label: "否",
            },
          ],
        },
        // {
        //   key: "recycleWay",
        //   type: "select",
        //   lableWidth:'100px',
        //   labelName: "补贴结算方式",
        //   placeholder: "请选择",
        //   option: [
        //     {
        //       value: "01",
        //       label: "线上结算",
        //     },
        //     {
        //       value: "02",
        //       label: "线下结算",
        //     },
        //   ],
        // },
        {
          key: "canOutQuote",
          type: "select",
          labelName: "是否允许外部竞价",
          lableWidth:'130px',
          placeholder: "请选择",
          option: [
            {
              value: "01",
              label: "是",
            },
            {
              value: "02",
              label: "否",
            },
          ],
        },
        {
          key: "outPayChannel",
          type: "select",
          labelName: "已成交外部订单款项垫付方",
          lableWidth:'180px',
          placeholder: "请选择",
          option: [
            {
              value: "Store",
              label: "门店",
            },
            {
              value: "Merchant",
              label: "回收商",
            },
          ],
        },
        {
          key: "niceBizState",
          type: "select",
          lableWidth: "80px",
          labelName: "靓机订单",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: "01",
              label: "回收商确认靓机",
            },
            {
              value: "03",
              label: "靓机已退回",
            },
            {
              value: "02",
              label: "取消靓机",
            },
            {
              value: "04",
              label: "门店确认靓机",
            }
          ],
        },
        {
          key: "quoteChannel",
          type: "select",
          lableWidth: "80px",
          labelName: "报价方式",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: "01",
              label: "审单报价",
            },
          ],
        },
      ],
      chxBiddingState:'',
      biddingList:[
        // { value: "", label: "全部" },
        { value: '01', label: "是" },
        // { value: false, label: "否" },
      ],
      optionList: [
        { value: "", label: "全部" },
        { value: true, label: "是" },
        { value: false, label: "否" },
      ],
      optionList2: [
        { value: "", label: "全部" },
        { value: "01", label: "是" },
        { value: "02", label: "否" },
      ],
      isUsedAddPrice: [
        { value: "", label: "全部" },
        { value: true, label: "是" },
        { value: false, label: "否" },
      ],
      otherDateType: "01",
      timeTypeOption: [
        {
          key: "01",
          label: "收货时间",
        },
        {
          key: "02",
          label: "退款时间",
        },
        {
          key: "03",
          label: "作废时间",
        },
        {
          key: "04",
          label: "取消时间",
        },
      ],
      formRules: {
        mobile: [
          {
            validator: formValidateMobile,
            trigger: "blur",
          },
        ],
      },
      SeachParams: {},
    };
  },
  created() {
    let startTime = moment()
      .day(moment().day())
      .format("YYYY-MM-DD 00:00:00"); // 当前时间往前推7天的时间
    let endTime = moment().format("YYYY-MM-DD 23:59:59");
    this.entryTime = this.$route.query.orderNo?null:[startTime, endTime];
    // 修改询价商家为多选
    if (this.$route.query.companyId) {
      this.companyIds = [Number(this.$route.query.companyId)] || [];
      this.getStoreSelectList(); //新加
    }
    this.merchantId = Number(this.$route.query.merchantId) || "";
    this.imei = this.$route.query.imei || "";
    this.orderNo = this.$route.query.orderNo || "";
    const params = {
      pageNum: 0,
      pageSize: 999,
    };
    _api.listSimpleMerchantView(params).then((res) => {
      if (res.code === 1) {
        this.shanpsSelectList = res.data;
      }
    });
    // 新加：撮合渠道
    _api.getMatchChannelSelect().then((r) => {
      const index = this.formItemList.findIndex((v) => v.key === "matchId");
      const data = Array.isArray(r.data) ? r.data : [];
      this.formItemList[index].option = data.map((v) => {
        return {
          value: v.id,
          label: v.name,
        };
      });
    });
    _api.getSelectList().then((res) => {
      if (res.code === 1) {
        this.InquirySelectList = res.data;
      }
    });
    _api.oldBrandSelectList().then((res) => {
      if (res.code === 1) {
        this.brandNoListData = res.data;
      }
    });
    this.getStatusList();
    console.log(this.classList);
    this.classList.forEach((ele) => {
      ele.label = ele.name;
      ele.value = ele.id;
    });
  },
  watch: {
    // companyId(newVal) {
    //   console.log(newVal,'询价商家变了');
    //   if (newVal != '') {
    //     this.isHasCompany = false
    //   } else {
    //     this.storeId = ''
    //     this.isHasCompany = true
    //   }
    // }
    // 修改：询价商家选了一个可以选询价门店，否则多选/不选不可以选询价门店
    companyIds(newVal) {
      console.log(newVal, "询价商家变了");
      if (newVal.length !== 1) {
        this.storeId = "";
        this.isHasCompany = true;
      } else {
        this.isHasCompany = false;
      }
    },
  },
  methods: {
    //通过分类请求机型
    getlistSelected(){
      this.modelListData = []
      if (this.typeId) {
        _api.getlistSelected({
          "machineTypeId": this.typeId,
          brandId: this.brandNo ? this.brandNoListData.find(item => item.brandNo == this.brandNo)?.id : '',
          "name": ""
        }).then((res) => {
          if (res.code === 1) {
            this.modelListData = res.data;
          }
        });
      }
    },
    //清除查询条件
    clearQueryCriteria() {
      this.$nextTick(() => {
        this.initData = {
          healthCode: null,
        };
        this.$refs.orderForm.$refs.validForm.resetFields();
      });
      //重置起始下单时间
      let startTimead = moment()
        .day(moment().day() - 6)
        .format("YYYY-MM-DD 00:00:00"); // 当前时间往前推7天的时间
      let endTimead = moment().format("YYYY-MM-DD 23:59:59");
      this.entryTime = [startTimead, endTimead];
      this.entryTime2 = null;
      this.entryTime3 = null;
      this.expressNo = "";
      this.typeId="";
      this.brandNo="";
      this.chxBiddingState='';
      this.orderNoOneline = "";
      this.phoneName = "";
      this.modelCode="";
      this.orderNo = "";
      this.imei = "";
      this.isEnablePay = "";
      this.userIsCollect = "";
      this.userPayeeMode = "";
      // 修改询价商家为多选
      this.companyIds = [];
      this.storeId = "";
      this.merchantId = "";
      this.state = [];
      this.endTime = null;
      this.startTime = null;
      this.ackStartTime = null;
      this.ackEndTime = null;
      this.otherStartTime = null;
      this.otherEndTime = null;
      this.pageNum = 1;
      this.pageSize = 10;
      this.staffName = "";
      this.isUsedAdd = "";
      // this.otherDateType = null;
      // this.handleConfirm(this.SeachParams);
    },
    // 修改询价商家为多选
    changeSelect(val) {
      console.log(val);
      if (!val || !val.length) {
        this.companyIds = [];
        return;
      }
      if (
        !val.includes("全部选择") &&
        val.length === this.InquirySelectList.length
      ) {
        this.companyIds.unshift("全部选择");
      } else if (
        val.includes("全部选择") &&
        val.length - 1 < this.InquirySelectList.length
      ) {
        this.companyIds = this.companyIds.filter((item) => {
          return item !== "全部选择";
        });
      }
      // 获取询价门店
      console.log(this.companyIds, "000000000000000");
      if (this.companyIds.length === 1) {
        this.storeId = "";
        this.getStoreSelectList();
      }
      console.log(this.companyIds, "询价商家222222");
    },
    removeTag(val) {
      if (val === "全部选择") {
        this.companyIds = [];
      }
    },
    selectAll() {
      if (this.companyIds.length < this.InquirySelectList.length) {
        this.companyIds = [];
        this.InquirySelectList.map((item) => {
          this.companyIds.push(item.id);
        });
        this.companyIds.unshift("全部选择");
      } else {
        this.companyIds = [];
      }
    },
    // **************************************
    // 获取状态下拉列表
    getStatusList() {
      _api.orderStatusList().then((res) => {
        let arr = [];
        for (const item of res.data) {
          arr.push({
            value: item.key,
            label: item.value,
          });
        }
        this.orderStatusList = arr;
      });
      // 机况
      _api.getHealthList().then((r) => {
        const index = this.formItemList.findIndex(
          (v) => v.key === "healthCode"
        );
        if (index >= 0) {
          this.formItemList[index].option = r.data.map((v) => {
            return {
              label: v.key,
              value: v.value,
            };
          });
        }
      });
    },
    handleChange(val) {
      this.merchantId = val;
      console.log(this.merchantId);
    },
    handleChangeInquiry(val) {
      console.log(val, "88888888");
      this.companyId = val;
      if (val != "") {
        this.storeId = "";
        this.getStoreSelectList();
      }
    },
    handleChangeStore(val) {
      this.storeId = val;
    },
    dateChange(e) {
      console.log(this.entryTime);
    },
    dateChange2(e) {
      console.log(this.entryTime);
    },
    searUnPayUserOrders() {
      this.state = ["01"];
      this.userIsCollect = false;
      this.entryTime = null;
      this.isEnablePay = true;
      this.userPayeeMode = "01";
      this.SeachParams.userIsCollect = false;
      this.SeachParams.isEnablePay = true;
      this.handleConfirm(this.SeachParams);
    },
    //回收分类选择
    recoverySeparator(){
      this.modelCode=""
      this.getlistSelected()
    },
    //查询
    handleConfirm(data,cd) {
      console.log("提交了form", data);
      console.log(this.entryTime);
      if (this.entryTime === null) {
        (this.startTime = ""), (this.endTime = "");
      } else {
        (this.startTime = moment(this.entryTime[0]).format("x")),
          (this.endTime = moment(this.entryTime[1]).format("x"));
      }
      console.log(this.entryTime2);
      if (this.entryTime2 === null) {
        (this.ackStartTime = ""), (this.ackEndTime = "");
      } else {
        (this.ackStartTime = moment(this.entryTime2[0]).format("x")),
          (this.ackEndTime = moment(this.entryTime2[1]).format("x"));
      }
      if (this.entryTime3 === null) {
        (this.otherStartTime = ""), (this.otherEndTime = "");
      } else {
        (this.otherStartTime = moment(this.entryTime3[0]).format("x")),
          (this.otherEndTime = moment(this.entryTime3[1]).format("x"));
      }
      this.companyIds = this.companyIds.filter((item) => {
        return item !== "全部选择";
      });
      const baseRequest = {
        makeUpTypePlatform:data.makeUpTypePlatform,
        isRenewSubsidy:data.isRenewSubsidy,
        openMachineCondition:data.openMachineCondition,
        canOutQuote:data.canOutQuote,
        outPayChannel:data.outPayChannel,
        channel: data.channel,
        typeId: this.typeId,
        brandNo: this.brandNo,
        chxBiddingState:this.chxBiddingState,
        isBindBarCode: data.isBindBarCode,
        niceAckPayment: data.niceAckPayment,
        shopType: data.shopType,
        expState: data.expState,
        recycleWay: data.recycleWay,
        niceBizState:data.niceBizState,
        quoteChannel:data.quoteChannel,
        orderNo: this.orderNo,
        expressNo: this.expressNo,
        orderNoOneline: this.orderNoOneline,
        imei: this.imei,
        phoneName: this.phoneName,
        modelCode:this.modelCode,
        receiptType: data.receiptType,
        // isNiceBiz: data.isNiceBiz
        //   ? true
        //   : data.isNiceBiz === false
        //   ? false
        //   : "",
        isNiceDevice: data.isNiceDevice
          ? true
          : data.isNiceDevice === false
          ? false
          : "",
        isReBuild: data.isReBuild
          ? true
          : data.isReBuild === false
          ? false
          : "",
        isInside: data.isInside ? true : data.isInside === false ? false : "",
        isExpected: data.isExpected
          ? true
          : data.isExpected === false
          ? false
          : "",
        isChxRebuild: data.isChxRebuild
          ? true
          : data.isChxRebuild === false
          ? false
          : "",
        isSameImeiAutoQuote: data.isSameImeiAutoQuote,
        isReimg: data.isReimg ? true : data.isReimg === false ? false : "",
        isAuto: data.isAuto ? true : data.isAuto === false ? false : "",
        // isMakeUp: data.isMakeUp ? true : data.isMakeUp === false ? false : '',
        makeUpType: data.makeUpType,
        signType: data.signType,
        isEnablePay: this.isEnablePay
          ? true
          : this.isEnablePay === false
          ? false
          : "",
        userIsCollect: this.userIsCollect
          ? true
          : this.userIsCollect === false
          ? false
          : "",
        userPayeeMode: this.userPayeeMode,
        // 新加撮合渠道
        matchId: data.matchId,
        // companyId: this.companyId,
        // 修改询价商家为多选
        companyId: this.companyIds,
        storeId: this.storeId,
        merchantId: this.merchantId,
        state: this.state,
        endTime: this.endTime,
        startTime: this.startTime,
        ackStartTime: this.ackStartTime,
        ackEndTime: this.ackEndTime,
        otherStartTime: this.otherStartTime,
        otherEndTime: this.otherEndTime,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        staffName: this.staffName,
        isUsedAddPrice: this.isUsedAdd,
        otherDateType: this.otherDateType,
        healthCode: data.healthCode || undefined,
        disuseLoding: () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      };
      this.SeachParams = baseRequest;
      console.log(baseRequest, this.SeachParams, "参数333");
      this.$store.commit("tagsView/SEACH_PARAMS", baseRequest);
      // this.$emit("SeachParams",baseRequest)
      // _api.ResidentTable(baseRequest).then(res =>{
      //   if(res.code===1){
      //     const seachDataList = res.data.records
      //     this.$store.commit('tagsView/POST_RESIDENT', seachDataList)
      //     this.$store.commit('tagsView/SEACH_PARAMS',baseRequest)
      //     this.$store.commit('tagsView/POST_TOTAL',res.data.total)
      //   }
      // })
    },
    // 获取门店列表
    getStoreSelectList() {
      // 修改：this.companyId --this.companyIds[0]
      _api.getStoreSelectList({ companyId: this.companyIds[0] }).then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.storeList = res.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
/deep/.query .el-tag.el-tag--info {
  background-color: #fbfbfb;
  border-color: #cecdcd;
  color: #666666;
}
.GlobalFormDemo {
  /deep/.el-checkbox-group {
    margin-left: 15px;
  }

  // /deep/.el-form-item--small.el-form-item{
  //   margin-bottom: 0;
  // }
  .flex {
    display: flex;
    align-items: center;
  }

  .time-type {
    /deep/.el-input__inner {
      border: none;
    }
  }
}
</style>
