<template>
    <div class="see-amount-dia">
      <el-dialog
        :title="'报价详情-'+(orderInfo.companyName || '')"
        :visible="dialogVisible"
        :close-on-click-modal="false"
        width="700px"
        top="3%"
        @close="close"
      >
        <div>
            <div class="flex-jb mb-10">
                <p>订单编号：{{ orderInfo.orderNo }}</p>
                <p>报价人：{{ orderInfo.quoterName }}</p>
            </div>
            <div class="flex-jb mb-10">
                <p>订单机型：{{ orderInfo.machineName }} {{ orderInfo.machineCapacity }}</p>
                <p>订单状态：
                  <span v-if="orderInfo.state === '00'">待确认</span>
                  <span v-else-if="orderInfo.state === '01'">已确认</span>
                  <span v-else-if="orderInfo.state === '02'">待支付</span>
                  <span v-else-if="orderInfo.state === '03'">已绑码</span>
                  <span v-else-if="orderInfo.state === '04'">已收货</span>
                  <span v-else-if="orderInfo.state === '05'">降价收货</span>
                  <span v-else-if="orderInfo.state === '10'">已取消</span>
                  <span v-else-if="orderInfo.state === '20'">已作废</span>
                  <span v-else-if="orderInfo.state === '30'">已退回</span>
                </p>
            </div>
            <div class="flex-jb mb-10">
                <p>出货价：{{ orderInfo.deliveryPrice }}（{{orderInfo.healthName}}）</p>
                <p style="color: #ff687b;">最终报价：￥{{ orderInfo.originalQuotePrice }}</p>
            </div>
        </div>
        <el-table
          ref="amountDia"
          :data="dialogTableData"
          v-loading="diaLoading"
          tooltip-effect="dark"
          max-height="500px"
          border
        >
          <el-table-column type="index" align="center" label="序号" width="50" />
          <el-table-column label="费用类型" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.channelName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="扣费项" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.optionName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="金额" align="center">
            <template slot-scope="{ row }">
              <span style="color: #ff687b;" v-if="row.opt == '00'">{{ row.price }}</span>
              <span style="color: #ff687b;" v-else-if="row.opt == '01'">-{{ row.price }}</span>
              <span style="color: #67c23a;" v-else-if="row.opt == '02'">+{{ row.price }}</span>
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click.native="close">关闭</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import _api from "@/utils/request";
  
  export default {
    props: {
      dialogVisible: {
        type: Boolean,
        default: false,
      },
      merchantId: [Number,String],
      orderNo: [String]
    },
    data() {
      return {
        diaLoading: false,
        dialogTableData: [],
        orderInfo: {}
      };
    },
  
    created() {
      this.dialogSearchAmount();
    },
    methods: {
      close() {
        this.$emit("close");
      },
      dialogSearchAmount() {
        this.diaLoading = true;
        _api
          .getExamineOrQuickInfo({orderNo:this.orderNo,merchantId:this.merchantId})
          .then((res) => {
            this.orderInfo = res.data;
            this.dialogTableData = res.data.feeInfoList
          })
          .finally(() => {
            this.diaLoading = false;
          });
      },
    },
  };
  </script>
  
  <style scoped lang="scss" ref="stylesheet/scss">
  .see-amount-dia {
    .nowrap-ellipsis {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    .flex-jb{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .flex-je {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .mb-10 {
      margin-bottom: 10px;
    }
    /deep/.el-form-item {
      margin-bottom: 10px !important;
    }
  }
  </style>
  